<template>
  <v-container class="pa-10" fluid>

    <v-row>
      <v-col cols="2"  >

        <v-card>

          <v-list color="primary_card">

            <template v-for="(item) in menu">

              <v-list-group  :prepend-icon="item.icon" v-if="$canAny(item.permission)" :key="item.key">

                <template v-slot:activator>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </template>
                <template v-for="(item) in item.children">
                  <v-list-item v-model="selectedItem" class="pointer pl-10" v-if="(item.permission === '') || $canAny(item.permission)"
                               :key="item.key"  @click="changeTab(item)">
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item>
                </template>
              </v-list-group>

            </template>

          </v-list>

        </v-card>

      </v-col>
      <v-col cols="10" >
        <v-card color="primary_card">
          <v-card-text>
            <component :is="tab"></component>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'

import RolesWidget from '@/app/widgets/roles-widget/RolesWidget'
import CallLinesWidget from '@/app/widgets/call-line-settings-widget/CallLinesWidget'
import RealmRingtoneWidget from '@/app/widgets/realm-ringtones-widget/RealmRingtoneWidget'
import GlobalSettingsWidget from '@/app/widgets/global-settings-widget/GlobalSettingsWidget'
import AccountSettingsWidget from '@/app/widgets/account-settings-widget/AccountSettingsWidget'
import GlobalRingtonesWidget from '@/app/widgets/global-ringtones-widget/GlobalRingtonesWidget'

import UsersWidget from '@/app/widgets/users-widget/UsersWidget'

import LogoWidget from '@/app/widgets/logo-widget/LogoWidget'
import LanguageWidget from '@/app/widgets/language-widget/LanguageWidget'
import TranslationsWidget from '@/app/widgets/translations-widget/TranslationsWidget'
import LogViewerWidget from '@/app/widgets/log-viewer-widget/LogsViewerWidget'
import ProxyWidget from '@/app/widgets/proxy-widget/ProxyWidget'

import ContactGroupsWidget from '@/app/widgets/contact-group-widget/ContactGroupsWidget'
import ContactsWidget from '@/app/widgets/contact-widget/ContactsWidget'
import SpeedDialWidget from '@/app/widgets/speed-dial-widget/SpeedDialWidget'
import CallRecordingsWidget from '@/app/widgets/call-recordings-widget/CallRecordingsWidget'
import ActivityLogsWidget from '@/app/widgets/activity-logs-widget/ActivityLogsWidget'
import RedisSettingsWidget from '@/app/widgets/redis-settings-widget/RedisSettingsWidget'
import ChatSettingsWidget from '@/app/widgets/chat-settings-widget/ChatSettingsWidget'

export default {
  name: 'DashboardPage',
  mixins: [],
  components: {},

  data: (vm) => ({
    tab: AccountSettingsWidget,
    selectedItem: 0,

    menu: [
      {
        key: 'user_settings',
        name: vm.$t('users_settings'),
        permission: ['can_get_users', 'can_change_admin_roles'],
        icon: 'mdi-account-supervisor-circle',
        children: [
          {
            key: 'users_settings',
            name: vm.$t('users_settings'),
            component: UsersWidget,
            cypress: 'users_settings',
            permission: 'can_get_users'
          },
          {
            key: 'roles_settings',
            name: vm.$t('roles_settings'),
            component: RolesWidget,
            cypress: 'roles_settings',
            permission: 'can_change_admin_roles'
          }
        ]

      },
      {
        key: 'contacts',
        name: vm.$t('contacts'),
        permission: ['can_add_contact', 'can_add_speed_dial', 'can_get_contact_book_groups'],
        icon: 'mdi-account-supervisor-circle',
        children: [
          {
            key: 'contact_groups',
            name: vm.$t('contact_groups'),
            component: ContactGroupsWidget,
            cypress: 'contact_groups',
            permission: 'can_get_contact_book_groups'
          },
          {
            key: 'contacts',
            name: vm.$t('contacts'),
            component: ContactsWidget,
            cypress: 'contacts',
            permission: 'can_add_contact'
          },
          {
            key: 'speed_dial',
            name: vm.$t('speed_dial'),
            component: SpeedDialWidget,
            cypress: 'speed_dial',
            permission: 'can_add_speed_dial'
          }
        ]

      },

      {
        key: 'settings',
        name: vm.$t('settings'),
        permission: ['can_change_admin_layout', 'can_change_admin_logo', 'can_get_logo'],
        icon: 'mdi-account-cog-outline',
        children: [
          {
            key: 'proxy',
            name: vm.$t('proxy'),
            component: ProxyWidget,
            cypress: 'proxy',
            permission: 'can_manage_proxy'
          },
          {
            key: 'logos_settings',
            name: vm.$t('logos_settings'),
            component: LogoWidget,
            cypress: 'logos_settings',
            permission: 'can_get_logo'
          },
          {
            key: 'global_settings',
            name: vm.$t('global_settings'),
            component: GlobalSettingsWidget,
            cypress: 'global_settings',
            permission: 'can_change_admin_global_settings'
          },
          {
            key: 'account_settings',
            name: vm.$t('account_settings'),
            component: AccountSettingsWidget,
            cypress: 'account_settings',
            permission: 'can_get_realms_settings'
          },
          {
            key: 'call_lines_settings',
            name: vm.$t('call_lines_settings'),
            cypress: 'call_lines_settings',
            component: CallLinesWidget,
            permission: 'can_get_call_lines'
          },
          {
            key: 'redis_settings',
            name: vm.$t('redis_settings'),
            cypress: 'redis_settings',
            component: RedisSettingsWidget,
            permission: 'can_get_information_from_redis'
          },
          {
            key: 'chat_settings',
            name: vm.$t('chat_settings'),
            cypress: 'chat_settings',
            component: ChatSettingsWidget,
            permission: 'can_manage_chat'
          }
        ]
      },
      {
        key: 'ringtone',
        name: vm.$t('ringtone'),
        permission: ['can_change_admin_global_settings', 'can_change_admin_account_settings', 'can_change_admin_account_settings', 'can_get_ringtones'],
        icon: 'mdi-phone-ring-outline',
        children: [
          {
            key: 'global_ringtone_settings',
            name: vm.$t('global_ringtone_settings'),
            component: GlobalRingtonesWidget,
            cypress: 'global_ringtone_settings',
            permission: 'can_change_admin_global_settings'
          },
          {
            key: 'realm_ringtone_settings',
            name: vm.$t('realm_ringtone_settings'),
            component: RealmRingtoneWidget,
            cypress: 'realm_ringtone_settings',
            permission: 'can_get_ringtones'
          }
        ]
      },
      {
        key: 'language',
        name: vm.$t('language_settings'),
        permission: ['can_manage_languages', 'can_manage_chs_translations', 'can_manage_cad_translations', 'can_get_languages', 'can_get_translations'],
        icon: 'mdi-translate',
        children: [
          {
            key: 'language_settings',
            name: vm.$t('language_settings'),
            component: LanguageWidget,
            cypress: 'language_settings',
            permission: ['can_get_languages']
          },
          {
            key: 'translations_settings',
            name: vm.$t('translations_settings'),
            component: TranslationsWidget,
            cypress: 'translations_settings',
            permission: ['can_manage_chs_translations', 'can_manage_cad_translations', 'can_get_translations']
          }
        ]
      },
      {
        key: 'logs',
        name: vm.$t('logs_settings'),
        permission: ['can_view_logs', 'can_view_call_recordings'],
        icon: 'mdi-math-log',
        children: [
          {
            key: 'log_viewer',
            name: vm.$t('log_viewer_settings'),
            component: LogViewerWidget,
            cypress: 'log_viewer_settings',
            permission: 'can_view_logs'
          },
          {
            key: 'recordings',
            name: vm.$t('call_recordings'),
            component: CallRecordingsWidget,
            cypress: 'call_recordings',
            permission: 'can_view_call_recordings'
          },
          {
            key: 'activiy_log',
            name: vm.$t('activiy_log'),
            component: ActivityLogsWidget,
            cypress: 'activiy_log',
            permission: 'can_view_activiy_log'
          }
        ]
      }
    ]
  }),

  computed: {
    ...mapGetters('global', ['authUserGetter'])
  },
  methods: {

    ...mapActions('settings', [
      'egressAuthAction',
      'getAccountsAction'
    ]),

    changeTab (item) {
      this.tab = item.component
    }

  },
  async mounted () {
    await this.egressAuthAction()
    await this.getAccountsAction()
  }

}
</script>

<style scoped>

</style>
