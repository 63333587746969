import instance from '@/modules/api/csp/instance'

// Modules
import authApiModule from '@/modules/api/csp/modules/auth.api.module'
import userApiModule from '@/modules/api/csp/modules/user.api.module'
import sipApiModule from '@/modules/api/csp/modules/sip.api.module'
import globalRingtoneApiModule from '@/modules/api/csp/modules/globalRingtone.api.module'
import realmRingtoneApiModule from '@/modules/api/csp/modules/realmRingtone.api.module'
import rolesApiModule from '@/modules/api/csp/modules/roles.api.module'
import permissionsApiModule from '@/modules/api/csp/modules/permissions.api.module'
import urlProxyApiModule from '@/modules/api/csp/modules/urlProxy.api.module'
import egressApiModule from '@/modules/api/csp/modules/egress.api.module'

import layoutsApiModule from '@/modules/api/csp/modules/layouts.api.module'
import usersApiModule from '@/modules/api/csp/modules/users.api.module'
import logosApiModule from '@/modules/api/csp/modules/logos.api.module'
import globalSettingsApiModule from '@/modules/api/csp/modules/globalSettings.api.module'
import realmSettingsApiModule from '@/modules/api/csp/modules/realmSettings.api.module'
import userSettingsApiModule from '@/modules/api/csp/modules/userSettings.api.module'
import connectionSettingsApiModule from '@/modules/api/csp/modules/connectionSettings.api.module'
import languageApiModule from '@/modules/api/csp/modules/language.api.module'
import appsApiModule from '@/modules/api/csp/modules/apps.api.module'
import translationsApiModule from '@/modules/api/csp/modules/translations.api.module'
import callLinesApiModule from '@/modules/api/csp/modules/callLines.api.module'
import logsApiModule from '@/modules/api/csp/modules/logs.api.module'
import proxyApiModule from '@/modules/api/csp/modules/proxy.api.module'

import contactGroupsApiModule from '@/modules/api/csp/modules/contactGroups.api.module'
import contactBookApiModule from '@/modules/api/csp/modules/contactBook.api.module'
import speedDialApiModule from '@/modules/api/csp/modules/speedDial.api.module'

import callLogDetailsApiModule from '@/modules/api/csp/modules/callLogDetails.api.module'
import activityLogsApiModule from '@/modules/api/csp/modules/activityLogs.api.module'
import callLogsApiModule from '@/modules/api/csp/modules/callLogs.api.module'

import redisSettingsApiModule from '@/modules/api/csp/modules/redisSettings.api.module'
import chatTextLinesApiModule from '@/modules/api/csp/modules/chatTextLines.api.module'

const apiService = {
  auth: authApiModule(instance),
  user: userApiModule(instance),
  sip: sipApiModule(instance),
  globalRingtone: globalRingtoneApiModule(instance),
  realmRingtone: realmRingtoneApiModule(instance),
  roles: rolesApiModule(instance),
  permissions: permissionsApiModule(instance),
  urlProxy: urlProxyApiModule(instance),
  egress: egressApiModule(instance),

  layouts: layoutsApiModule(instance),
  users: usersApiModule(instance),
  logos: logosApiModule(instance),
  globalSettings: globalSettingsApiModule(instance),
  realmSettings: realmSettingsApiModule(instance),
  userSettings: userSettingsApiModule(instance),

  connectionSettings: connectionSettingsApiModule(instance),
  languages: languageApiModule(instance),
  apps: appsApiModule(instance),
  translations: translationsApiModule(instance),
  callLines: callLinesApiModule(instance),
  logs: logsApiModule(instance),
  proxy: proxyApiModule(instance),

  contactBook: contactBookApiModule(instance),
  contactGroups: contactGroupsApiModule(instance),
  speedDial: speedDialApiModule(instance),

  callLogDetails: callLogDetailsApiModule(instance),
  activityLogs: activityLogsApiModule(instance),
  callLogs: callLogsApiModule(instance),

  redisSettings: redisSettingsApiModule(instance),
  chatTextLines: chatTextLinesApiModule(instance),

  instance
}

export default apiService
