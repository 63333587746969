<template>
  <div>
    <div class="d-flex align-center mb-2">
      <h2 class="mr-10" >
        {{ $t('account_settings')}}
      </h2>
      <v-select style="max-width: 300px"  class="ml-auto" v-if="$can('has_access_to_all_realms')" @change="loadSettings"
                :label="$t('realm')"
                v-model="realmName"
                :items="realms"
      ></v-select>
    </div>
    <v-row class="align-center">
      <v-col class="col-2">
        <v-menu
          ref="dateMenu"
          v-model="dateMenu"
          :close-on-content-click="false"
          :return-value.sync="dateFrom"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-combobox
              v-model="dateFrom"
              prepend-icon="mdi-calendar"
              readonly
              :label="$t('log_date_picker_from')"
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-combobox>
          </template>
          <v-date-picker
            v-model="dateFrom"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="dateMenu = false"
            >
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="saveDate(null)"
            >
              {{ $t('clear') }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="saveDate(dateFrom)"
            >
              {{ $t('save') }}
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col v-if="dateFrom">
        <v-menu
          ref="timeMenu"
          v-model="timeMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="timeFrom"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="timeFrom"
              :label="$t('time_from')"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            format="24hr"
            v-model="timeFrom"
            full-width
            scrollable
            @click:minute="$refs.timeMenu.save(timeFrom)"
          ></v-time-picker>
        </v-menu>
      </v-col>
      <v-col class="col-2">
        <v-menu
          ref="dateMenuTo"
          v-model="dateMenuTo"
          :close-on-content-click="false"
          :return-value.sync="dateTo"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-combobox
              v-model="dateTo"
              prepend-icon="mdi-calendar"
              readonly
              :label="$t('log_date_picker_to')"
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-combobox>
          </template>
          <v-date-picker
            v-model="dateTo"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="dateMenu = false"
            >
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="saveDateTo(null)"
            >
              {{ $t('clear') }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="saveDateTo(dateTo)"
            >
              {{ $t('save') }}
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col v-if="dateTo">
        <v-menu
          ref="timeMenuTo"
          v-model="timeMenuTo"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="timeTo"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="timeTo"
              :label="$t('time_to')"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            format="24hr"
            v-model="timeTo"
            full-width
            scrollable
            @click:minute="$refs.timeMenuTo.save(timeTo)"
          ></v-time-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-text-field
          name="call_id"
          v-model="call_id"
          :label="$t('call_id')"
          clearable
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          name="callee_id_number"
          v-model="callee_id_number"
          :label="$t('inbound_caller_id')"
          clearable
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          name="caller_id_number"
          v-model="caller_id_number"
          :label="$t('outbound_caller_id')"
          clearable
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          name="sipAddress"
          v-model="sipAddress"
          :label="$t('sip_address')"
          clearable
        ></v-text-field>
      </v-col>
      <v-col class="col-1">
        <v-btn color="success" @click="filterLogs">{{ $t('filter') }}</v-btn>
      </v-col>
    </v-row>
    <div>
      <v-progress-linear indeterminate rounded color="amber" height="20" v-if="downloading"></v-progress-linear>
      <br />
      <div class="d-flex align-center" v-if="!downloading">
        <audio autoplay style="width: 100%" controls :src="file" :title="audioFileTitle"></audio>
        <!-- <v-btn color="success" class="ml-2" :disabled="!file" @click="copyURL">{{ $t('copy_url') }}</v-btn> -->
      </div>
      <br />
    </div>
    <v-data-table
      :headers="headers"
      :items="recordings"
      disable-sort
      :loading="loading"
      :items-per-page="-1"
      hide-default-footer
    >
      <template v-slot:body="{ items }" v-if="recordings.length">
        <tbody>
          <tr :class="key === selectedRow ? 'selected-row' : ''" v-for="(item, key) in items" :key="item.call_id">
            <td>{{ item.callee_id_number }}</td>
            <td>{{ item.caller_id_number }}</td>
            <td>{{ item.custom_channel_vars.Username }}</td>
            <td>{{ item.direction }}</td>
            <td>{{ convertEgressDate(item.start) }}</td>
            <td>
              <v-btn color="success" @click="() => handleClick(key, item)">{{ $t('play') }}</v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <div>
      <v-btn color="success" @click="onPrev" class="ma-2" :disabled="!kazooPager.prevKeys.length">{{ $t('prev') }}</v-btn>
      <v-btn color="success" @click="onNext" class="ma-2" :disabled="kazooPager.nextKey === undefined">{{ $t('next') }}</v-btn>
    </div>
  </div>
</template>

<script>
import apiService from '@/modules/api/csp'
import CONSTANTS from '@/constants'

export default {
  name: 'CallRecordingsWidget',
  data: (vm) => ({
    selectedRow: null,
    loading: true,
    headers: [
      {
        text: vm.$t('inbound_caller_id'),
        value: 'callee_id_number'
      },
      {
        text: vm.$t('outbound_caller_id'),
        value: 'caller_id_number'
      },
      {
        text: vm.$t('agent'),
        value: 'agent'
      },
      {
        text: vm.$t('direction'),
        value: 'direction'
      },
      {
        text: vm.$t('date'),
        value: 'start'
      },
      {
        text: vm.$t('action'),
        value: 'action'
      }
    ],
    recordings: [],

    file: null,
    realms: [],
    call_id: '',
    settings: [],
    realmName: '',
    downloading: false,
    mediaLoaded: false,
    audioFileTitle: '',
    callee_id_number: '',
    caller_id_number: '',
    realmNameReadOnly: '',

    dateTo: '',
    dateFrom: '',

    timeTo: '',
    timeFrom: '',

    dateMenu: null,
    dateMenuTo: null,

    timeMenu: null,
    timeMenuTo: null,

    sipAddress: '',

    kazooPager: {
      currKey: null,
      nextKey: null,
      prevKeys: [],
      pageSize: 20
    }
  }),
  watch: {
    realmName () {
      this.sendInitialRequest()
    }
  },
  methods: {
    // copyURL () {
    //   navigator.clipboard.writeText()

    //   this.notificationSnackBarAction({
    //     show: true,
    //     color: 'success',
    //     text: this.$t('url_copied')
    //   })
    // },
    async loadRealms () {
      await apiService.sip.getRealms().then((result) => {
        this.realms = result.filter(n => n != null)
      }).catch((e) => {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },
    async loadSettings () {
      if (this.realmName) {
        // when admin changes realm - reload read only info
        this.realmNameReadOnly = this.realmName
      }
      await apiService.realmSettings.load(this.realmName).then((result) => {
        this.settings = result.data
      }).catch((e) => {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },
    async handleClick (row, recording) {
      this.selectedRow = row

      const realm = await apiService.sip.getRealmInfo({
        realm: this.realmName
      })

      this.downloading = true

      await apiService.egress.getRecordedMedia(realm.account_id, recording.custom_channel_vars['Media-Recording-ID']).then(response => {
        // record.media_file = response.data
        const reader = new FileReader()
        reader.readAsDataURL(response.data)

        reader.onloadend = async () => {
          this.file = reader.result
          this.mediaLoaded = true

          this.downloading = false
          this.audioFileTitle = recording.custom_channel_vars['Bridge-ID']
        }
      })
    },
    convertEgressDate (date) {
      // return this.$moment.unix(date - CONSTANTS.KAZOO_TIME_STAMP_DIFF).format('MM/DD/YYYY HH:mm:ss')
      return new Date((date - CONSTANTS.KAZOO_TIME_STAMP_DIFF) * 1000)
    },
    onPrev () {
      this.selectedRow = null
      const pageKey = this.kazooPager.prevKeys.pop()

      if (!pageKey) return

      const params = this.getParams()

      params.start_key = pageKey

      this.sendRequest(params)
    },
    onNext () {
      this.selectedRow = null
      this.kazooPager.prevKeys.push(this.kazooPager.currKey)

      const params = this.getParams()

      params.start_key = this.kazooPager.nextKey

      this.sendRequest(params)
    },
    sendRequest (params) {
      this.loading = true
      this.recordings = []
      apiService.egress.getRecordings(this.currentRealm.account_id, params).then(result => {
        this.loading = false
        this.recordings = result.data.data

        this.kazooPager.currKey = result.data.start_key
        this.kazooPager.nextKey = result.data.next_start_key
      }).catch(() => {
        this.loading = false
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('something_went_wrong_please_try_again_later')
        })
      })
    },
    async sendInitialRequest () {
      this.kazooPager.prevKeys = []

      this.currentRealm = await apiService.sip.getRealmInfo({
        realm: this.realmName
      })

      const params = this.getParams()

      this.sendRequest(params)
    },
    saveDate (date) {
      this.$refs.dateMenu.save(date)
    },
    saveDateTo (date) {
      this.$refs.dateMenuTo.save(date)
    },
    filterLogs () {
      this.sendInitialRequest()
    },
    getParams () {
      const params = {
        page_size: this.kazooPager.pageSize,
        page: 1
      }

      if (this.call_id) {
        params.filter_call_id = this.call_id.trim()
      }

      if (this.callee_id_number) {
        params.filter_callee_id_number = this.callee_id_number.trim()
      }

      if (this.caller_id_number) {
        params.filter_caller_id_number = this.caller_id_number.trim()
      }

      params.created_to = CONSTANTS.KAZOO_TIME_STAMP_DIFF

      if (this.dateTo) {
        params.created_to += Math.floor(new Date(this.dateTo).getTime() / 1000)
      }

      if (this.timeTo) {
        params.created_to += this.getTime(this.timeTo)
      }

      if (params.created_to === CONSTANTS.KAZOO_TIME_STAMP_DIFF) delete params.created_to

      params.created_from = CONSTANTS.KAZOO_TIME_STAMP_DIFF

      if (this.dateFrom) {
        params.created_from += Math.floor(new Date(this.dateFrom).getTime() / 1000)
      }

      if (this.timeFrom) {
        params.created_from += this.getTime(this.timeFrom)
      }

      if (params.created_from === CONSTANTS.KAZOO_TIME_STAMP_DIFF) delete params.created_from

      if (this.sipAddress.trim()) {
        params['filter_custom_channel_vars.Username'] = this.sipAddress?.split('@')[0]
      }

      return params
    },
    getTime (timeString) {
      const times = timeString.split(':')

      return (parseInt(times[0]) + this.$moment().zone() / 60) * 3600 + times[1] * 60
    }
  },
  async mounted () {
    await this.loadRealms()

    if (this.realms.length > 0) {
      this.realmName = this.realms[0]
    }

    await this.loadSettings()

    this.sendInitialRequest()
  }
}
</script>

<style>
.selected-row{
  background-color: #1d972f
}
</style>
