export default {
  nga911: 'NGA911',

  // Errors
  wrong_credentials: 'Wrong credentials',
  something_went_wrong_please_try_again_later: 'Something went wrong, please try again later!',

  // Validation
  e_mail_is_required: 'E-mail is required',
  e_mail_must_be_valid: 'E-mail must be valid',
  password_is_required: 'Password is required',
  password_must_be_greater_than_3_characters: 'Password must be greater than 3 characters',
  two_fa_code_is_required: 'Two-factor authentication (2FA) code is required',
  two_factor: 'Two-factor authentication (2FA)',
  // General
  loading: 'Loading... Please wait',
  search: 'Search',
  action: 'Action',
  number: 'Number',
  date: 'Date',

  // Fields
  email: 'Email',
  password: 'Password',
  sip_password: 'Sip password',
  authorization: 'Authorization',

  // General setting tab
  prefix: 'Prefix',
  tfa_code: '2FA Code',
  old_password: 'Old Password',
  login_number: 'Login Number',
  phone_number: 'Phone Number',
  new_password: 'New Password',
  logout_number: 'Logout Number',
  darklight_mode: 'Dark/Light Mode',
  lightdark_mode: 'Light/Dark Mode',
  change_password: 'Change Password',
  password_change: 'Password Change',
  previous_password: 'Previous Password',
  leftright_dial_pad: 'Left/right Dial Pad',
  repeat_new_password: 'Repeat New Password',
  multi_factor_authentication: 'Multi-factor Authentication',
  queue_auth_via_phone_number: 'Queue Auth Via Phone Number',
  set_prefix_for_outgoing_calls: 'Set Prefix For Outgoing Calls',
  authorization_with_phone_number: 'Authorization with phone number',
  phone_number_is_missing: 'Phone number is missing!',

  // Audio arbitration tab
  request_microphone_access: 'Request microphone access',
  application_does_not_have_access_to_your_microphone: 'Application does not have access to your microphone!',

  // Connection setting tab
  save: 'Save',
  proxy: 'Proxy',
  check: 'Check',
  add_new: 'Add New',
  priority: 'Priority',

  // Controller setting tab
  open: 'Open',
  pair: 'Pair',
  state: 'State',
  close: 'Close',
  setup: 'Setup',
  remove: 'Remove',
  usb_vendor_id: 'USB vendor ID',
  usb_product_id: 'USB product ID',
  opened: 'Opened',
  closed: 'Closed',
  you_dont_have_paired_controllers: "You don't have paired controllers.",

  // Layout setting tab
  layout: 'Layout',
  layout_will_be_applied_after_re_logging_or_page_refresh: 'Layout will be applied after re-logging or page refresh.',

  // SIP setting tab
  add: 'Add',
  edit: 'Edit',
  delete: 'Delete',
  default: 'Default',
  eg_alice: 'eg: Alice',
  sip_address: 'Sip Address',
  account_name: 'Account Name',
  display_name: 'Display Name',
  make_default: 'Make Default',
  edit_sip_address: 'Edit sip address',
  your_account_name: 'Your Account Name',
  add_new_sip_address: 'Add new sip address',
  your_sip_address_password: 'Your sip address password',

  // SIP setting modal
  audio_settings: 'Audio settings',
  general_settings: 'General settings',
  controller_settings: 'Controller settings',
  connection_settings: 'Connection settings',
  sip_address_marked_as_default: 'Sip address marked as default',

  cant_update_sip_address: 'Error. Can\'t update sip address',
  cant_add_sip_address: 'Error. Can\'t add sip address',
  please_choose_a_valid_account_name: 'Error. Please choose a valid account name',
  sip_address_created: 'Sip address created',
  sip_address_updated: 'Sip address updated',
  // texts
  welcome: 'WELCOME',
  website_title: 'NGA911',
  phone: 'Phone',
  type: 'Type',
  add_new_contact: 'Add New Contact',
  import_contacts: 'Import Contact book',
  call: 'Call',
  transfer: 'Transfer',
  username: 'Username',
  privilege_level: 'Privilege level',
  admin: 'Admin',
  user: 'User',
  not_ready: 'not ready',
  ready: 'ready',
  do_you_want_to_disconnect: 'Do you want to disconnect?',
  admin_calls: 'Admin calls',
  emergency_calls: 'Emergency calls',
  hold: 'hold',
  mute: 'mute',
  from: 'from',
  map: 'Map',
  notes: 'Notes',
  text: 'Text',
  details: 'Details',

  // Admin
  admin_settings: 'Admin Settings',
  roles: 'Roles',
  create_role: 'Create Role',
  name: 'Name',
  update_role: 'Update Role',
  role_deleted: 'Role Deleted',
  cancel: 'Cancel',
  role: 'Role',
  role_updated: 'Role Updated',
  role_created: 'Role Created',
  description: 'Description',
  actions: 'Actions',
  global_settings: 'Global Settings',
  account_settings: 'Account Settings',
  global_ringtone_settings: 'Global Ringtones',
  realm_ringtone_settings: 'Realm Ringtones',
  users_settings: 'Users',
  roles_settings: 'Roles',
  logos_settings: 'Logos',
  layout_settings: 'Layout Templates',
  dashboard_template: 'Layout Template',
  template_preview: 'Template preview',
  call_history_settings: 'Call History',
  create_layout: 'Create layout',
  update_layout: 'Update layout',
  layout_updated: 'Layout updated',
  layout_created: 'Layout created',
  layout_deleted: 'Layout deleted',
  widgets: 'Widgets',
  slots: 'Slots',
  call_lines_settings: 'Call lines settings',

  all: 'All',
  create_user: 'Create user',
  update_user: 'Update user',
  user_deleted: 'User deleted',
  user_created: 'User created',
  user_updated: 'User updated',

  import_users: 'Import users',
  users_imported_successfully: 'Users imported successfully',
  changes_for_account: 'Changes for account',
  import_from_ippbx: 'Import users from IPPBX',
  set_ippbx_url: 'Set IPPBX url',
  ippbx_url: 'IPPBX url',
  choose_realm: 'Please choose realm',
  email_extension: 'Email extension',

  expire_after_in_days: 'Expire After (In days)',
  mobile_phone: 'Mobile phone',
  permissions: 'Permissions',
  pass_upper: 'Upper Case Letters',
  pass_number: 'Numbers',
  pass_lower: 'Lower Case Letters',
  expire_after: 'Expire after',
  never: 'Never',

  ringtone_uploaded: 'Ringtone uploaded',
  choose_file: 'Choose file',
  create_ringtone: 'Create ringtone',
  ringtone: 'Ringtone',
  changes_will_be_applied_after_relogin_or_page_refresh: 'Changes will be applied after re-logging or page refresh',
  only_active: 'Only active',
  logo: 'Logo',
  realm: 'Realm',
  upload_logo: 'Upload logo',
  logo_deleted: 'Logo deleted',
  logo_uploaded: 'Logo uploaded',
  for_the_best_look_use_a_square_picture: 'For the best look. Use a square picture',

  global_setting_updated: 'Global setting updated',
  options: 'Options',
  automatic_location_update_interval: 'Automatic Location Update Interval',
  update_global_setting: 'Update global setting',
  value: 'Value',
  enabled: 'Enabled',
  disabled: 'Disabled',
  active: 'Active',
  update_realm_setting: 'Update realm setting',
  realm_setting_updated: 'Realm setting updated',
  update_user_realm_setting: 'Update user realm setting',
  realm_settings_updated: 'User realm setting updated',
  value_should_be_between: 'Value should be between {min} and {max}',
  CallControlComponent: 'Call Control',
  EmergencyCallTabsComponent: 'Emergency Call',
  CallHistoryTabsComponent: 'Call History & Abandoned Calls',
  SpeedDialTabsComponent: 'Speed Dial, Contact Book & Agents',
  LogoAndClockComponent: 'Logo & Clock',
  EmergencyCallLineComponent: 'Emergency Call Line',
  AdminCallComponent: 'Admin Calls',

  MapsTab: 'Map',
  configuration: 'Configuration',
  widget_config: 'Widget Configuration',
  cant_assign_layout_please_try_again: 'Can\'t assign layout. Please try again',

  fill_height: 'Fill Height',
  fill_height_hint: 'Check this checkbox when this component is alone in slot',
  visible: 'Visible',

  // settings connection settings
  socket_connected_successfully: 'Socket connected successfully',
  socket_connection_failed: 'Socket connection failed',

  // contacts
  create_contact: 'Create contact',
  update_contact: 'Update contact',
  contact_permission: 'Contact permission',
  contact_created: 'Contact created',
  contact_updated: 'Contact updated',
  contact_imported: 'Contact imported',
  contact_groups: 'Contact groups',
  create_contact_group: 'Create contact group',
  update_contact_group: 'Update contact group',
  color: 'Color',
  contact_group_updated: 'Contact group updated',
  contact_group_created: 'Contact group created',
  contact_groufp_deleted: 'Contact group deleted',
  contact_group: 'Contact Group',

  file: 'File',
  upload: 'Upload',
  contact_book: 'Contact book',
  agents: 'Agents',

  // speed dial
  speed_dial: 'Speed dial',
  create_speed_dial: 'Create speed dial',
  update_speed_dial: 'Update speed dial',
  bind_contact: 'Bind contact',
  order_by: 'Order by',
  icon: 'Icon',
  contact: 'Contact',
  contacts: 'Contact Book',
  speed_dial_updated: 'Speed dial updated',
  speed_dial_created: 'Speed dial created',
  button_deleted: 'Button deleted',
  background_color: 'Background color',
  clear_color: 'Clear color',
  folder: 'Folder',
  order: 'Order',
  back: 'Back',

  please_confirm_that_you_want_to_delete_this: 'Please confirm that you want to delete this {what}?',
  confirm: 'Confirm',
  discard: 'Discard',
  warning: 'Warning',

  // tooltip
  settings: 'Settings',
  monitoring: 'Monitoring',
  logout: 'Logout',
  information: 'Information',
  connected: 'Connected',
  disconnected: 'Disconnected',
  call_channels: 'Call Channels',
  admin_panel: 'Admin Panel',
  abandoned_call: 'Abandoned Call',
  call_history: 'Call History',

  // tabs
  call_information: 'Call Information',
  held: 'Held',
  adr: 'ADR',
  // Held And ADR information
  country: 'Country',
  A1: 'State, Region,',
  A2: 'County, District ',
  A3: 'City, Township',
  A4: 'City division, District',
  A5: 'Neighborhood, Block',
  PRM: 'Street name pre-modifier',
  PRD: 'Leading street direction',
  RD: 'Primary road or street',
  STS: 'Street suffix or type',
  POD: 'Trailing street suffix',
  POM: 'Street name post-modifier',
  RDSEC: 'Road section',
  RDBR: 'Road branch',
  RDSUBBR: 'Road sub-branch',
  HNO: 'House number',
  HNS: 'House number suffix',
  LMK: 'Landmark or vanity address',
  FLR: 'Floor',
  NAM: 'Name residence and office occupant',
  PC: 'Postal/zip code',
  BLD: 'Building structure',
  UNIT: 'Unit apartment, suite',
  ROOM: 'Room number',
  SEAT: 'Seat desk, cubicle, workstation',
  PLC: 'Type of place',
  PCN: 'Postal community name',
  POBOX: 'Post office box',
  ADDCODE: 'Additional code',
  lat: 'Latitude',
  lng: 'Longitude',

  fcc_master_registry: 'FCC Master Registry',
  psap_id: 'Psap Id',
  psap_name: 'Psap Name',
  county: 'County',
  city: 'City',
  type_of_change: 'Type of Change',
  comments: 'Comments',
  last_modified: 'Last Modified',

  please_select_user: 'Please select at least on user to add',
  sip_type: 'Sip type',
  language_settings: 'Languages',
  create_language: 'Create Language',
  update_language: 'Update Language',
  language_updated: 'Language updated',
  language_created: 'Language created',

  translations_settings: 'Translations',
  application: 'Application',
  language: 'Language',
  load: 'Load',
  translation_key: 'Translation Key',
  translation_value: 'Translation Value',
  filters: 'Filters',
  not_translated: 'Not Translated',
  missing_language_or_application: 'Missing Language or Application',
  choose_translations: 'Choose Translations',
  english: 'English',
  add_call_line: 'Add custom call line',
  call_line: 'Call line',
  call_line_name: 'Custom name',
  call_line_key: 'Custom INVITE header',
  call_line_value: 'Custom INVITE header value',
  call_lines_added: 'New custom line was added',
  call_lines_update: 'Update current custom call line',
  call_lines_updated: 'New custom line was updated',
  call_lines_header_key: 'Header key',
  call_lines_header_value: 'Header value',
  logs_settings: 'Logs',
  log_viewer_settings: 'Call Logs',
  log_date_picker: 'Choose date',
  log_time_picker: 'Choose time',
  clear: 'Clear',
  log_search_text: 'Search in logs',
  log_apps: 'Select APP',
  log_switch_active: 'View active logs',
  log_message: 'Message',
  log_api_module: 'API Module',
  log_serenity: 'Serenity',
  log_app: 'Application',
  log_view_full_log: 'View full log',
  domain: 'Domain',
  is_required: 'Is required',
  is_not_valid_email: 'Is not valid email',
  create: 'Create',
  created: 'Created',
  updated: 'Updated',
  add_new_number: 'Add new number',
  public: 'Public',
  private: 'Private',
  update: 'Update',
  make_primary: 'Make primary',
  remove_phone_number: 'Remove phone number',
  delete_all_contacts: 'Delete all contacts',
  delete_selected: 'Delete selected',
  all_contacts_for_realm: 'All contact book for realm',
  import: 'Import',
  add_to_root: 'Add to root',
  deleted: 'Deleted',
  order_updated: 'Order updated',
  all_speed_dials: 'All speed dials for realm',
  delete_all_speed_dials: 'Delete all speed dials',
  selected_speed_dial: 'Selected speed dial',
  parent_folder: 'Parent Folder',

  url: 'URL',
  stage: 'Stage',
  method: 'Method',
  call_id: 'Call ID',
  response: 'Response',
  log_search_call_id: 'Search call ID',
  log_search_sip_address: 'Search sip address',
  call_recordings: 'Call Recordings',

  callee: 'Callee',
  caller: 'Caller',
  Date: 'Date',

  log_date_picker_to: 'Choose date to',
  log_date_picker_from: 'Choose date from',

  activiy_log: 'Activity logs',
  position: 'Position',
  message_count: 'Total logs',
  direction: 'Direction',
  response_time: 'Response time',
  from_number: 'From number',
  filter: 'Filter',
  prev: 'Prev',
  next: 'Next',
  caller_id_number: 'Caller id number',
  callee_id_number: 'Callee id number',
  download: 'Download',
  time_interval: 'Time interval',
  log_level: 'Log level',

  incident_id: 'Incident ID',
  play: 'Play',

  redis_settings: 'Redis settings',

  redis_name: 'Redis name',
  redis_value: 'Redis value',
  redis_percent: 'Redis percent',
  drop_last: 'Drop Last',
  inbound_caller_id: 'Inbound caller ID',
  outbound_caller_id: 'Outbound caller ID',
  agent: 'Agent'
}
